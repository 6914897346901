import Icon from "@mdi/react";
import React from "react";

type CVSectionProps = {
  children: React.ReactNode,
  className?: string,
  icon: string,
  title: string,
}

export function CVSection({
  children,
  className,
  icon,
  title,
}: CVSectionProps) {
  return (
    <section className={className}>
      <header className="flex items-center p-4">
        <span className="font-mono text-yellow-800 dark:text-yellow-600 text-sm tracking-widest uppercase whitespace-nowrap">{title}</span>
        <hr className="border-amber-500 border-t-2 mx-2 w-full" />
        <Icon className="flex-shrink-0 text-yellow-800 dark:text-yellow-600" path={icon} size="1.5rem" />
      </header>

      {children}
    </section>
  );
}