import Icon from "@mdi/react";

type ChipProps = {
  icon: string,
  label: string,
};

export function Chip({
  icon,
  label,
}: ChipProps) {
  return (
    <span className="bg-white dark:bg-zinc-800 border border-zinc-300 dark:border-zinc-700 flex items-center pl-2 pr-3 py-1 rounded-full space-x-1">
      <Icon path={icon} size="1.5rem" />
      <span>{label}</span>
    </span>
  );
}