import classnames from "classnames";

const avatar = new URL("../../assets/avatar.jpg", import.meta.url);

type AvatarProps = {
  className?: string;
};

export function Avatar({
  className
}: AvatarProps) {
  return (
    <img
      alt="avatar"
      className={classnames("h-24 xl:h-32 object-cover object-center rounded-full w-24 xl:w-32", className)}
      src={avatar.toString()}
    />
  );
}