const logo = new URL("../../assets/fkr-waves.svg", import.meta.url);

export function Logo() {
  return (
    <img
      alt="logo"
      className="h-8"
      src={logo.toString()}
    />
  );
}