import { mdiArmFlex, mdiConsole, mdiDatabase, mdiElectronFramework, mdiEmail, mdiFirefox, mdiFolderOpen, mdiGit, mdiGithub, mdiGitlab, mdiGraphql, mdiHammerWrench, mdiInstagram, mdiLanguageCsharp, mdiLanguageCss3, mdiLanguageHtml5, mdiLanguageJavascript, mdiLanguageTypescript, mdiLinkedin, mdiMicrosoftVisualStudio, mdiMicrosoftVisualStudioCode, mdiNodejs, mdiReact, mdiSass, mdiTailwind, mdiTimeline, mdiTwitter, mdiVuejs } from "@mdi/js";
import React from "react";
import { Avatar } from "./components/Avatar/Avatar";
import { Chip } from "./components/Chip/Chip";
import { CVSection } from "./components/CVSection/CVSection";
import { Logo } from "./components/Logo/Logo";
import { SocialButton } from "./components/SocialButton/SocialButton";
import { TimelineEntry } from "./components/TimelineEntry/TimelineEntry";

export const App = () => {
  const handleMailButtonClick: React.MouseEventHandler = (ev) => {
    ev.preventDefault();

    window.location.href = `mailto:${atob("aGVqc2FuQGZrci5maQ==")}`;
  };

  return (
    <div className="bg-orange-50 dark:bg-zinc-900 flex flex-col min-h-screen">
      <header className="bg-gradient-to-tr from-amber-500 to-yellow-400 flex h-48 xl:h-60 items-center justify-center">
        <div className="font-alt font-bold italic -skew-y-3 text-yellow-800 text-5xl transform-gpu uppercase">Hejsan 👋</div>
      </header>

      <main className="xl:flex max-w-screen-2xl mx-auto">
        <section className="flex-shrink-0 xl:w-[342px]">
          <div className="-mt-12 xl:-mt-16 px-4">
            <div className="flex items-end xl:justify-center mb-8">
              <Avatar className="mr-auto xl:mr-0 ring-4 ring-orange-50 dark:ring-zinc-900" />
              <div className="flex xl:hidden mb-2 -mr-2 space-x-2">
                <SocialButton href="https://github.com/vainamov" icon={mdiGithub} label="GitHub" />
                <SocialButton href="https://twitter.com/vainamov" icon={mdiTwitter} label="Twitter" />
                <SocialButton href="https://instagram.com/vainamov" icon={mdiInstagram} label="Instagram" />
                <SocialButton href="https://www.linkedin.com/in/fabian-krahtz/" icon={mdiLinkedin} label="LinkedIn" />
                <SocialButton icon={mdiEmail} label="E-Mail" onClick={handleMailButtonClick} />
              </div>
            </div>

            <div className="font-serif font-bold mb-4 text-amber-500 text-4xl xl:text-center" style={{ textShadow: "1px 1px 0 #854D0E" }}>Fabian Krahtz</div>
            <div className="font-alt font-medium leading-snug text-zinc-700 dark:text-zinc-400 text-lg xl:text-center">I'm an Application Developer<br />based in Hannover, Germany</div>

            <div className="xl:flex flex-wrap gap-2 hidden justify-center mt-8">
              <SocialButton href="https://github.com/vainamov" icon={mdiGithub} label="GitHub" />
              <SocialButton href="https://twitter.com/vainamov" icon={mdiTwitter} label="Twitter" />
              <SocialButton href="https://instagram.com/vainamov" icon={mdiInstagram} label="Instagram" />
              <SocialButton href="https://www.linkedin.com/in/fabian-krahtz/" icon={mdiLinkedin} label="LinkedIn" />
              <SocialButton icon={mdiEmail} label="E-Mail" onClick={handleMailButtonClick} />
            </div>
          </div>
        </section>

        <section className="xl:gap-y-4 lg:grid grid-cols-2 mt-8 w-full">
          <CVSection icon={mdiTimeline} title="Professional Career">
            <div className="p-4 pt-0 space-y-4 text-zinc-800 dark:text-zinc-200">
              <TimelineEntry
                description="Full-Time Application Developer"
                from="since"
                highlight
                tags={["React", "TypeScript", "AWS", "Magnolia CMS"]}
                title="Neoskop GmbH"
                to="05|22"
              />

              <TimelineEntry
                description="Full-Time Software-Engineer"
                from="03|21"
                tags={["Odoo ERP", "Python", "Dart", "Flutter", "GCP", "Kubernetes", "PostgreSQL"]}
                title="42 N.E.R.D.S. GmbH"
                to="05|22"
              />

              <TimelineEntry
                description="Full-Time Software-Engineer intern"
                from="05|20"
                tags={["Odoo ERP", "Python", "Vue", "GCP", "Kubernetes", "PostgreSQL"]}
                title="42 N.E.R.D.S. GmbH"
                to="09|20"
              />

              <TimelineEntry
                description={<>Bachelor of Science<br />Applied CS (undergraduate studies)</>}
                from="09|16"
                tags={["C#", "C++", "OpenGL", "Arduino", "Assembler", "JSP", "Oracle DB", "MariaDB"]}
                title="Ostfalia – University of Applied Sciences"
                to="01|21"
              />
            </div>
          </CVSection>

          <CVSection icon={mdiFolderOpen} title="Noteworthy Side Projects">
            <div className="p-4 pt-0 space-y-4 text-zinc-800 dark:text-zinc-200">
              <TimelineEntry
                description={<>
                  Web development and branding solutions for private explorations, group projects and external work<br />
                  See more at <a className="text-amber-500 underline underline-offset-4" href="https://marine.si/" target="_blank">marine.si</a>
                </>}
                from="since"
                highlight
                tags={["Node", "Vue", "React", "TypeScript", "Express", "GraphQL", "SQLite", "C#"]}
                title="Marine Creative"
                to="03|21"
              />
              <TimelineEntry
                description={<>
                  Software, web and app development and university projects<br />
                  See more at <a className="text-amber-500 underline underline-offset-4" href="https://festival.tf/" target="_blank">festival.tf</a>
                </>}
                from="since"
                highlight
                tags={["C#", "Node", "Vue", "TypeScript", "Express", "MySQL"]}
                title="FΞSTIVΛL"
                to="11|14"
              />
              <TimelineEntry
                description={<>
                  Web Developer and Designer for the video game network RewisServer<br />
                  Senior Web Developer since 07/19<br />
                  Lead Web Developer since 09/20<br />
                  Team Manager since 01/21
                </>}
                from="02|19"
                tags={["Node", "Vue", "TypeScript", "Express", "GraphQL", "MongoDB"]}
                title="Volix UG (haftungsbeschränkt)"
                to="04|21"
              />
            </div>
          </CVSection>

          <CVSection className="col-span-2" icon={mdiArmFlex} title="Skills">
            <div className="p-4 pt-0 space-y-4 text-zinc-800 dark:text-zinc-200">
              <div className="flex flex-wrap font-alt gap-2">
                <Chip icon={mdiLanguageTypescript} label="TypeScript" />
                <Chip icon={mdiLanguageJavascript} label="JavaScript" />
                <Chip icon={mdiLanguageCsharp} label="C#" />
                <Chip icon={mdiLanguageHtml5} label="HTML" />
                <Chip icon={mdiLanguageCss3} label="CSS" />
                <Chip icon={mdiSass} label="SCSS" />
                <Chip icon={mdiReact} label="React" />
                <Chip icon={mdiVuejs} label="Vue" />
                <Chip icon={mdiNodejs} label="Node" />
                <Chip icon={mdiElectronFramework} label="Electron" />
                <Chip icon={mdiGraphql} label="GraphQL" />
                <Chip icon={mdiTailwind} label="Tailwind" />
              </div>
            </div>
          </CVSection>

          <CVSection className="col-span-2" icon={mdiHammerWrench} title="Tools and Technologies">
            <div className="p-4 pt-0 space-y-4 text-zinc-800 dark:text-zinc-200">
              <div className="flex flex-wrap font-alt gap-2">
                <Chip icon={mdiMicrosoftVisualStudioCode} label="Visual Studio Code" />
                <Chip icon={mdiMicrosoftVisualStudio} label="Visual Studio" />
                <Chip icon={mdiGit} label="Git" />
                <Chip icon={mdiGithub} label="GitHub" />
                <Chip icon={mdiGitlab} label="GitLab" />
                <Chip icon={mdiDatabase} label="PostgreSQL" />
                <Chip icon={mdiDatabase} label="MongoDB" />
              </div>
            </div>
          </CVSection>
        </section>
      </main>

      <footer className="flex flex-col lg:flex-row items-center max-w-screen-2xl mt-auto mx-auto p-4 text-zinc-700 dark:text-zinc-500 text-sm w-full">
        <Logo />
        <div className="font-medium lg:ml-4 mt-6 lg:mt-0">&copy; 2022 FKR.FI</div>
      </footer>
    </div>
  );
};
