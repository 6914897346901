import classNames from "classnames";
import React from "react";
import { Tag } from "../Tag/Tag";

type TimelineEntryProps = {
  description: React.ReactNode,
  from: string,
  highlight?: boolean,
  tags?: string[],
  title: React.ReactNode,
  to: string,
}

export function TimelineEntry({
  description,
  from,
  highlight = false,
  tags,
  title,
  to,
}: TimelineEntryProps) {
  // const isDarkMode = window.matchMedia("(prefers-color-scheme: dark)").matches;

  return (
    <article className={classNames("bg-white dark:bg-zinc-800 border dark:border-zinc-700 flex p-2 rounded-md", {"border-amber-500 dark:border-amber-500": highlight})}>
      <aside className="ml-1 mr-3">
        <div className={classNames("font-mono font-medium text-xl", {"text-amber-500": highlight, "text-zinc-400": !highlight})}>{from}</div>
        <div className={classNames("font-mono font-medium text-xl", {"text-amber-500": highlight, "text-zinc-400": !highlight})}>{to}</div>
      </aside>

      <main className="font-alt">
        <div className="font-semibold mb-2 mt-0.5">{title}</div>

        <div>{description}</div>

        {tags && (
          <div className="flex flex-wrap gap-1 mt-2">
            {tags.map((tag) => <Tag label={tag} />)}
          </div>
        )}
      </main>
    </article>
  );
}