type TagProps = {
  label: string,
};

export function Tag({
  label,
}: TagProps) {
  return (
    <span className="bg-zinc-100 font-medium px-1.5 py-0.5 rounded text-zinc-600 text-xs">
      {label}
    </span>
  );
}